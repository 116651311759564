.faq{
  min-height: 20rem;
  background: url(../../assets/images/bg_faq.png) top center no-repeat;
  background-size: 100% 100%;
  padding: 0 2.6rem;
  .container{
    padding-top: 2rem;
    .dog{
      img{
        // margin-top: 2.3rem;
        width: 100%;
      }
      .title{
        margin: 0;
        font-family: 'chalkduster';
        color: #ffffff;
        font-size: .5rem;
        line-height: .7rem;
        text-align: center;
      }
    }
    
    .list{
      .item{
        font-family: 'chalkduster';
        margin-top: 1rem;
        .title{
          font-size: .4rem;
          line-height: 1;
          color: #86DE32;
        }
        .as{
          font-size: .32rem;
          line-height: .4rem;
          font-family: 'chalkboard';
          color: #fff;
          margin-top: .2rem;
        }
      }
    }
    .bottom{
      display: flex;
      // align-items: center;
      // justify-content: center;
      flex-direction: column;
      margin-top: 1.2rem;
      .l{
        margin-bottom: .8rem;
        img{
          height: 1.85rem;
        }
      }
      .right{
        display: flex;
        align-items: center;
        .m,.r{
          width: 2.4rem;
          margin-right:.32rem;
        }
      }
    }
  }
}

.mobileFaq{
  padding-top: .64rem;
  padding-bottom: .21rem;
  background: url(../../assets/mobile/faq_bg.png) top center no-repeat;
  background-size: 100% 100%;
  .container{
    padding: 0 .22rem;
    .title{
      color: #fff;
      font-size: .24rem;
      line-height: .32rem;
      font-family: 'AvenirNext-Medium';
    }
    .list{
      margin-top: .22rem;
      .item{
        margin-top: .36rem;
        .title{
          font-size: .16rem;
          line-height: .19rem;
          font-family: 'MEBlack';
          color: #2CFF98;
        }
        .as{
          color: #fff;
          font-size: .15rem;
          line-height: .2rem;
          font-family: 'AvenirNext-Medium';
          margin-top: .08rem;
        }
      }
    }
    .bottom{
      margin-top: .52rem;
      .tip{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: .20rem;
        grid-column-gap: .2rem;
        grid-auto-flow: column dense;
        margin-top: .2rem;
      }
    }
  }
}