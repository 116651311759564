@import "../../../style/index.scss";

.header {
  width: 100vw;
  height: 120 / $base;
  position: fixed;
  // position: absolute;
  left: 0;
  top: 0;
  padding: 0 260 / $base;
  z-index: 10000;

  &-blur {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    // background-color: rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), transparent);
      // -webkit-backdrop-filter: blur(10 / $base);
      // backdrop-filter: blur(10 / $base);
    z-index: 10001;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  &-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 10002;
    padding-top: 31 / $base;
    padding-bottom: 27 / $base;
    // border-bottom: .01rem solid #e0e2e44d;
  }

  &-logo {
    width: 300 / $base;
    height: 72 / $base;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &-nav {
    display: flex;
    align-items: center;

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 40 / $base;
      cursor: pointer;

      &:nth-last-child(1) {
        margin-right: 0;
      }
      .content{
        width: .64rem;
        height: .64rem;
        position: relative;
        overflow: hidden;
        .img{
          width: 100%;
          // height: 1.28rem;
          transition: all .3s linear;
          display: flex;
          flex-direction: column;
        }
        img{
          width: 100%;
        }
      }

      // &:first-child {
      //   margin-right: 77 / $base;
      // }

      &:hover,&.active {
        .content{
         
          .img{
            transform: translateY(-50%);
          }
         
        }
        // .header-nav-item-title {
        //   color: $primary-text-color;
        // }

        // .header-nav-item-doll {
        //   width: 16 / $base;
        // }
      }

      &-title {
        font-size: 18 / $base;
        line-height: 22/ $base;
        color: #fff;
        font-family: "MERegular";
        // margin-bottom: 4 / $base;
        img{
          width: 36 / $base;
        }
      }

      &-doll {
        width: 0vw;
        height: 2 / $base;
        transition: all 0.15s;
        border-radius: 1px;
        background-color: $primary-text-color;
      }
    }
  }
}
