@import '../../style/index.scss';

.home {
  // width: 100vw;
  // height: 100vh;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // font-size: 1rem;
  // color: black;
  .videoBox{
    padding-top: 56%;
    background: url(../../assets/images/bg.png) bottom center no-repeat;
    background-size: cover;
    position: relative;
  }
  .bottom{
    min-height: 21.6rem;
    background: url(../../assets/images/home_bg.png) top center no-repeat;
    background-size: 100% 100%;
    position: relative;
    background-color: #141B3B;
    .line{
      position: absolute;
      // max-width: 1416 / $base;
      width: 1416 / $base;
      top:0;
      bottom:0;
      left: 49.5%;
      transform: translateX(-50%);
      background: url(../../assets/images/line.png) top center no-repeat;
      background-size: cover;
    }
    .left{
      position: absolute;
      top: 0;
      left: 0;
      width: 2.78rem;
      height: 100%;
      background: url(../../assets/images/left.png) top center no-repeat;
      background-size: 100% 100%;
    }
    .right{
      position: absolute;
      top: 0;
      right: 0;
      width: 3.05rem;
      height: 100%;
      background: url(../../assets/images/right.png) top center no-repeat;
      background-size: 100% 100%;
    }
    .title{
      width: 15.2rem;
      height: 3.7rem;
      background: url(../../assets/images/story_bg.png) top center no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.23rem 1rem .88rem;
      margin: auto;
      color: #00F3F6;
      font-family: 'MERegular';
      font-size: .26rem;
      line-height: .4rem;
    }
    .list{
      width: 15.2rem;
      margin: auto;
      margin-top: .25rem;
      .item{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: .94rem;
        padding-right: 1.16rem;
        padding-bottom: .66rem;
        &:last-child{
          padding-bottom: 0;
        }
        .pic{
          width: 2.6rem;
          height: 2.6rem;
          img{
            width: 100%;
          }
        }
        .text{
          padding-left: .5rem;
          flex: 1;
          color: #fff;
          .name{
            font-size: .36rem;
            line-height: .46rem;
            font-family: 'chalkboard';
            margin-bottom: .24rem;
            color: #93F825;
          }
          .des{
            font-size: .26rem;
            line-height: .4rem;
            font-family: 'chalkboard';
          }
        }
      }
    }
  }
}



.mobilebody{
  padding: .44rem 0;
  min-height: 8.14rem;
  background: url(../../assets/mobile/home_bg.png) top center no-repeat;
  background-size: 100% 100%;
  padding-bottom: 1.05rem;
  .top{
    width: 3.75rem;
    height: 3.6rem;
    margin: auto;
    margin-top: .5rem;
    background: url(../../assets/mobile/phone_story_home.png) top center no-repeat;
    background-size: 100% 100%;
    position: relative;
    .text{
      width: 2.78rem;
      height: 1.4rem;
      position: absolute;
      bottom: .43rem;
      left: 50%;
      transform: translateX(-50%);
      font-size: .14rem;
      line-height: 1.33;
      color: #00F3F6;
      font-family: 'AvenirNext-Heavy';
    }
  }
  .original{
    margin-top: .13rem;
    &-swiper{
      width: 2.6rem;
      margin: auto;
      // .swiper-slide{
      //   // width: 1.4rem!important;
      //   // height: 1.4rem!important;
      // }
      // .original-swiper-content{
      //   // width: 1.4rem!important;
      //   // height: 1.4rem!important;
      //   width: 100%;
      // }
    }
    &-info{
      width: 3.2rem;
      margin: auto;
      color: #fff;
      text-align: center;
      &-title{
        font-family: 'MERegular';
        font-size: .16rem;
        line-height: 1.4;
        margin-top: .16rem;
      }
      &-detail{
        font-family: 'AvenirNext-Heavy';
        font-size: .12rem;
        line-height: 2;
        margin-top: .08rem;
      }
    }
  }
  .btn_bottom{
    height: .8rem;
    background: #4D395C;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    a{
      font-family: 'MEBlack';
      font-size: .14rem;
      line-height: 1.4;
      text-align: center;
      border: none;
      outline: none;
      background: url(../../assets/mobile/home_btn_bg.png) top center no-repeat;
      background-size: 100% 100%;
      width: 3.3rem;
      height: .44rem;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}