@import '../../style/index.scss';


.planet{
  // background: url(../../assets/images/planet_bg.png) bottom right no-repeat;
  background: url(../../assets/images/planet_bg2.png) bottom right no-repeat;
  background-size: 100vw 56.25vw;
  min-height: 100vh;
  background-color: #141B3B;
  .content{
    max-width: 92vw;
    position: relative;
    margin: 0 auto;
  }
  .game_block{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    .game_box{
      width: 10.8rem;
      height: 8.6rem;
      position: relative;
      padding-top: 3.41rem;
      .game_bg{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url(../../assets/images/game_bg.webp) bottom right no-repeat;
        background-size: cover;
        pointer-events: none;
      }
      .content{
        width: 7.84rem;
        height: 3.83rem;
        background-color: #000;
        padding: .14rem;
        .list{
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: .12rem;
          .item{
            width: 2.35rem;
            height: 3.31rem;
            background-color: #AA7C73;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: .12rem;
            img{
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
  
  .whiteList{
    .title{
      height: .76rem;
      img{
        height: 100%;
      }
      margin-bottom: .28rem;
    }
    .list{
      // width: 8rem;
      // height: 4.4rem;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      flex-wrap: wrap;
      .item{
        width: 50%;
        color: #fff;
        font-size: .22rem;
        line-height: .4rem;
        font-family: 'MERegular';
      }
    }
  }
  .newContent{
    padding: 0 130 / $base;
    padding-top: 1.6rem;
    .title{
      margin: 0;
      font-family: "chalkduster";
      color: #ffffff;
      font-size: 0.5rem;
      line-height: 0.7rem;
      text-align: center;
    }
    .box{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .game_block{
      min-height: auto;
    }
  }
}


::-webkit-scrollbar{
  display: none;
}

.LitepaperModel{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  // z-index: 999999;
  opacity: 0;
  // z-index: -1;
  .content{
    width: 6.29rem;
    height: 6.24rem;
    background: url(../../assets/images/tip_bg.png) bottom right no-repeat;
    background-size: 100% 100%;
    padding-top: .92rem;
    position: relative;
    .close{
      width: .86rem;
      height: .86rem;
      background: url(../../assets/images/tip_close.png) bottom right no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: .4rem;
      top: 0;
    }

    .imgBox{
      width: 2.84rem;
      height: 2.84rem;
      margin: auto;
      margin-top: .8rem;

      img{
        width: 100%;
      }
    }
    .trueAnsWer{
      font-family: 'AvenirNext-Heavy';
      font-size: .28rem;
      line-height: .44rem;
      color: #fff;
      text-align: center;
      margin-top: .2rem;
    }
    .text{
      width: 5.08rem;
      height: 4.18rem;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-family: 'AvenirNext-Heavy';
      color: #fff;
      padding: 0 .26rem;
      padding-top: .74rem;
      p{
        font-size: .28rem;
        line-height: 1.57;
      }
      button{
        width: 3.5rem;
        height: .88rem;
        background: url(../../assets/images/pop_btn.png) bottom right no-repeat;
        background-size: 100% 100%;
        font-size: .26rem;
        line-height: 1.57;
        border: none;
        outline: none;
        color: #fff;
        margin-top: .38rem;
        font-family: 'AvenirNext-Heavy';
      }
    }
  }
}




.PlanetMobile{
  width: 100%;
  overflow-x: hidden;
  padding-top: .64rem;
  padding-bottom: 1.12rem;
  background: url(../../assets/mobile/phone_bg_back.png) top center no-repeat;
  background-size: 100% 100%;
  .container{
    padding: 0 .22rem;
    position: relative;
    .text{
      margin-bottom: 2.4rem;
      .title{
        font-size: 'MEBlack';
        font-size: .24rem;
        line-height: .32rem;
        color: #fff;
      }
      p{
        font-size: 'AvenirNext-Medium';
        font-size: .15rem;
        line-height: .2rem;
        color: #fff;
        margin-bottom: .2rem;
      }
    }
    .game{
      margin-bottom: 4.6rem;
      position: relative;
      .title{
        font-size: 'MEBlack';
        font-size: .24rem;
        line-height: .32rem;
        color: #fff;
      }
      p{
        font-size: 'AvenirNext-Medium';
        font-size: .15rem;
        line-height: .2rem;
        color: #fff;
        margin-bottom: .2rem;
      }
      .game_block{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        .game_box{
          width: 4.02rem;
          height: 3.2rem;
          position: relative;
          padding-top: 1.33rem;
          .game_bg{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: url(../../assets/images/game_bg.webp) bottom right no-repeat;
            background-size: 100%;
            pointer-events: none;
          }
          .content{
            // position: relative;
            // z-index: 2;
            width: 2.82rem;
            height: 1.32rem;
            margin: auto;
            background-color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
        
            // padding: .14rem;
            // display: none;
            .list{
              display: flex;
              align-items: center;
              justify-content: space-around;
              padding: 0.08rem;
              width: 100%;
              .item{
                width: .84rem;
                height: 1.19rem ;
                background-color: #AA7C73;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: .12rem;
                img{
                  width: 100%;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }

    .bottom{
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 0;
      .contain{
        width: 100%;
        height: 1.12rem;
        overflow-x: scroll;
        overflow-y: hidden;
        background-color: #141B3B;
        padding: .12rem;
        .list{
          margin: 0 -0.04rem;
          width: 4.8rem;
          .item{
            width: .88rem;
            height: .88rem;
            box-sizing: border-box;
            display: inline-block;
            margin: 0 .04rem;
            opacity: .5;
            &.active{
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .bg{
    width: 2.40rem;
    height: 2.05rem;
    position: fixed;
    right: 0;
    bottom: 1.12rem;
    img{
      width: 100%;
    }
  }

  .LitepaperModel{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    // z-index: 999999;
    opacity: 0;
    // z-index: -1;
    .content{
      width: 3.2rem;
      height: 3.17rem;
      background: url(../../assets/images/tip_bg.png) bottom right no-repeat;
      background-size: 100% 100%;
      padding-top: .46rem;
      position: relative;
      .close{
        width: .43rem;
        height: .43rem;
        background: url(../../assets/images/tip_close.png) bottom right no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right: .2rem;
        top: 0;
      }
      .imgBox{
        width: 1.42rem;
        height: 1.42rem;
        margin: auto;
        margin-top: .4rem;

        img{
          width: 100%;
        }
      }
      .trueAnsWer{
        font-family: 'AvenirNext-Heavy';
        font-size: .14rem;
        line-height: .22rem;
        color: #fff;
        text-align: center;
        margin-top: .1rem;
      }
      .text{
        width: 2.58rem;
        height: 2.12rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: 'AvenirNext-Heavy';
        color: #fff;
        padding: 0 .18rem;
        padding-top: .45rem;
        p{
          font-size: .14rem;
          line-height: 1.4;
        }
        button{
          width: 1.78rem;
          height: .44rem;
          background: url(../../assets/images/pop_btn.png) bottom right no-repeat;
          background-size: 100% 100%;
          font-size: .13rem;
          line-height: 1.6;
          border: none;
          outline: none;
          color: #fff;
          margin-top: .19rem;
          font-family: 'AvenirNext-Heavy';
        }
      }
    }
  }
  
}

