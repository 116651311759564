.mint{
  // height: 100vh;
  position: relative;
  // overflow: hidden;
  .mint_box{
    position: relative;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  
    &_con{
      inset: 0;
      position: absolute;
      background: url('../../assets/images/mint_bg.png') center no-repeat;
      background-size: 100% 100%;
    }
    .mint_video{
      height: 100%;
      width: 177.778vh;
      min-width: 100%;
      min-height: 56.25vw;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .contract_btn{
      // width: 80vh;
      // height: 3.25rem;
      width: 680px;
      height: 294px;
      // min-width: 100%;
      // min-height: 56.25vw;
      // background-color: rgba(0, 255, 255, 0.254);
      position: absolute;
      // top: 4.31rem;
      // left: 50%;
      // transform: translateX(-50%);
      left: 50%;
      top: 55%;
      transform: translate(-50%, -50%);
      text-indent: -100rem;
      border-radius: .08rem;
      cursor: pointer;
    }
  }
  .mint_info{
    position: relative;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    .bg{
      height: 100%;
      width: 177.778vh;
      min-width: 100%;
      min-height: 56.25vw;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &_con{
      inset: 0;
      position: absolute;
      background: url('../../assets/images/mint_bg.png') center no-repeat;
      background-size: 100% 100%;
    }
    .mint_info{
      height: 100%;
      width: 177.778vh;
      min-width: 100%;
      min-height: 56.25vw;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .info{
      width: 12.04rem;
      height: 8.3rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-family: 'MERegular';
      color: #fff;
      .head{
        width: 6.37rem;
        margin: auto;
        img{
          width: 100%;
        }
      }
      .center{
        width: 100%;
        height: 3.6rem;
        margin-top: -.40rem;
        background: url('../../assets/images/mint_c.png') center no-repeat;
        background-size: 100% 100%;
        padding-top: .45rem;
        .address{
          font-size: .24rem;
          text-align: center;
          height: 1.8rem;
          .subtitle{
            line-height: 2.916;
          }
          p{
            line-height: 1.67;
          }
        }
        .box{
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: .24rem;
          .subtitle{
            line-height: 2.916;
          }
          p{
            line-height: 1.67;
            span{
              color: #00FFFE;
            }
          }
          .suply{
            width: 6.05rem;
          }
          .status{
            flex: 1;
          }
        }
      }
      .bottom{
        width: 100%;
        height: 2.17rem;
        margin-top: -0.15rem;
        background: url('../../assets/images/mint_b.png') center no-repeat;
        background-size: 100% 100%;
        padding: 0 .54rem;
        padding-top: .83rem;
        font-size: .2rem;
        line-height: 2;
      }
    }
  }

  
}

.mintPage{
  .container{
    position: relative;
    height: 100vh;
    min-height: 100vh;
    // overflow: hidden;
    .mint_box_con{
      inset: 0;
      position: absolute;
      background: url('../../assets/images/mint_bg.png') 50% no-repeat;
      background-size: 100% 100%;
    }
    .mint_video{
      height: 100%;
      width: 177.778vh;
      min-width: 100%;
      min-height: 56.25vw;
      top: 50%;
      position: absolute;
      left: 50%;
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
    }
    .mint_block{
      display: flex;
      min-height: 100vh;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .mint_com{
        width: 8.92rem;
        height: 7.25rem;
        position: relative;
        padding-top: 3.41rem;
        .mint_bg{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: url(../../assets/images/game_bg.webp) bottom right no-repeat;
          background-size: cover;
          pointer-events: none;
          z-index: 2;
        }
        .mint_content{
          position: absolute;
          bottom: 1.15rem;
          left: 1.1rem;
          width: 6.56rem;
          height: 3.23rem;
          background: url(../../assets/images/mint_Box_bg.png) bottom right no-repeat;
          background-size: 100% 100%;
          padding-top: .5rem;
          .text{
            font-family: 'MEUltrabold';
            font-size: .24rem;
            line-height: 1.4;
            color: #fff;
            text-align: center;
            .num{
              font-size: .41rem;
              line-height: 1.4;
            }
            .sale{
              color: #2CFF98;
            }
            .address{
              // width: 80%;
              // margin: auto;
              // word-break: break-all;
              // margin-top: .2rem;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 .2rem;
            }
            .btn{
              // width: 3.3rem;
              // height: .7rem;
              width: 4.2rem;
              height: 1rem;
              background: url('../../assets/images//pop_btn.png');
              background-size: 100% 100%;
              border: none;
              outline: none;
              padding: 0;
              color: #fff;
              font-family: 'MEBlack';
              font-size: .26rem;
            }
          }
          .box {
            display: flex;
            align-items: stretch;
            justify-content: center;
            // min-height: .66rem;
            min-height: .26rem;

            .count{
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .inputGroup{
              text-align: center;
              width: 2rem;
              background-color: #21212159;
              line-height: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              box-sizing: border-box;
            }
            
            .inputGroup button{
                width: .44rem;
                height: .44rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: .4rem;
                color: rgba(255, 255, 255, 0.8);
                background-color: #21212159;
                border: .01rem solid #ffffff71;
            }
            
            .inputGroup input{
                min-width: 1px;
                width: .44rem;
                height: .44rem;
                font-family: DDBFont;
                text-align: center;
                font-size: .32rem;
                color: rgba(255, 255, 255, 0.5);
                background-color: #21212159;
                border: .01rem solid #ffffff71;
                pointer-events: none;
                &:focus,&:hover,&:active{
                  border: .01rem solid #ffffff71;
                }
            }
            
            .inputGroup input:focus{
                border: none;
                outline: none;
            }
          }
        }
      }
    }
  }
}


.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.spinner-border {
  display: inline-block;
  width: .3rem;
  height: .3rem;
  border: 0.28em solid #ffffff;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border;
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}



.mobileMint{
  min-height: 100vh;
  background: url(../../assets//mobile/phone_bg_mint.png) no-repeat bottom center;
  background-size: 100vw 100vh;
  background-attachment: fixed;
  .container{
    position: relative;
    height: 100%;

    
    .mint_block{
      display: flex;
      min-height: 100vh;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .mint_com{
        width: 100%;
        height: 2.6rem;
        position: relative;
        padding-top: 2.8rem;
        margin-top: -1rem;
        .mint_bg{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: url(../../assets/images/game_bg.webp) center no-repeat;
          background-size: 100%;
          pointer-events: none;
          z-index: 2;
        }
        .mint_content{
          position: absolute;
          bottom: 0;
          left: 0rem;
          width: 100%;
          height: 2.6rem;
          background: url(../../assets//images//mint_Box_bg.png) center center no-repeat;
          background-size: 2.8rem 1.34rem;
          background-position-y: .9rem;
          padding-top: 1.1rem;
          .text{
            font-family: 'MEUltrabold';
            font-size: .14rem;
            line-height: 1.4;
            color: #fff;
            text-align: center;
            .num{
              font-size: .20rem;
              line-height: 1.4;
            }
            .sale{
              color: #2CFF98;
            }
            .address{
              // width: 80%;
              // margin: auto;
              // word-break: break-all;
              // margin-top: .2rem;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 .2rem;
            }
            
          }
          .box {
            display: flex;
            align-items: stretch;
            justify-content: center;
            min-height: .66rem;
            width: 60%;
            margin: auto;

            .count{
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .inputGroup{
              text-align: center;
              background-color: #21212159;
              line-height: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              box-sizing: border-box;
            }
            
            .inputGroup button{
                width: .3rem;
                height: .3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: .12rem;
                color: rgba(255, 255, 255, 0.8);
                background-color: #21212159;
                border: .01rem solid #ffffff71;
            }
            
            .inputGroup input{
                min-width: 1px;
                width: .3rem;
                height: .3rem;
                font-family: DDBFont;
                text-align: center;
                font-size: .14rem;
                color: rgba(255, 255, 255, 0.5);
                background-color: #21212159;
                border: .01rem solid #ffffff71;
                pointer-events: none;
                border-radius: none;
                &:focus,&:hover,&:active{
                  border: .01rem solid #ffffff71;
                }
            }
            
            .inputGroup input:focus{
                border: none;
                outline: none;
            }
          }
        }
        .mint_btn{
          // width: 1.40rem;
          // height: .48rem;
          width: 2rem;
          height: 0.48rem;
          background: url('../../assets/images//pop_btn.png');
          background-size: 100% 100%;
          border: none;
          outline: none;
          padding: 0;
          color: #fff;
          font-family: 'MEBlack';
          font-size: .13rem;
          display: block;
          margin: auto;
        }
      }
    }
  }
}