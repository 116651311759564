
@font-face {
  font-family: MELight;
  src: url(./../../assets/fonts/MonumentExtended-Light.ttf);
}
@font-face {
  font-family:MERegular;src:url(../../assets/fonts/MonumentExtended-Regular.ttf);
}

@font-face {
  font-family:MEUltrabold;src:url(../../assets/fonts/MonumentExtended-Ultrabold.otf) format("opentype")
}

@font-face {
  font-family:MEBlack;src:url(../../assets/fonts/MonumentExtended-Black.ttf)
}

@font-face {
  font-family:CBBI;src:url(../../assets/fonts/Comic-Book-Bold-Italic.otf) format("opentype")
}

@font-face {
  font-family:'Monument Extended';src:url(../../assets/fonts/MonumentExtended-Regular.ttf);
  // src: url();
}
@font-face {
  font-family:AOMFont;src:url(../../assets/fonts/Attack-Of-Monster.ttf);
}

@font-face {
  font-family: AvenirNext-Heavy;
  src: url('../../assets/fonts/AvenirNext-Heavy-09.ttf');
}

@font-face {
  font-family: AvenirNext-Medium;
  src: url('../../assets/fonts/AvenirNext-Medium-06.ttf');
}

@font-face {
  font-family: chalkboard;
  src: url('../../assets/fonts/Chalkboard.ttc') format('truetype');
}

@font-face {
  font-family: chalkduster;
  src: url('../../assets/fonts/Chalkduster.ttf');
}