.mindMap{
  min-height: 41rem;
  background: url(../../assets/images/bg_roadmap.png) top center no-repeat;
  background-size: 100% 100%;
  padding: 0 2.6rem;
  .container{
    padding-top: 2.8rem;
    .title{
      color: #2CFF98;
      font-size: .5rem;
      line-height: .7rem;
      font-family: 'chalkboard';
      // height: .7rem;
      // img{
      //   height: 100%;
      // }
    }
    .list{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 3rem;
      grid-row-gap: .2rem;
      grid-column-gap: .2rem;
      grid-auto-flow: column dense;
      .item{
        height: 4.97rem;
        .top{
          height: 2.52rem;
        }
        .bottom{
          margin-top: .2rem;
          height: 2.25rem;
        }
      }
    }
    .chapter{
      padding-top: 2.53rem;
      &_title{
        color: #2CFF98;
        font-size: .5rem;
        line-height: .7rem;
        font-family: 'chalkboard';
        height: .76rem;
        img{
          height: 100%;
        }
      }
      .des{
        font-size: .22rem;
        line-height: .4rem;
        font-family: 'MERegular';
        color: #fff;
        margin: .28rem 0 .48rem;
      }
    }
  }
}

.mobileRoadmap{
  padding-top: .64rem;
  padding-bottom: .21rem;
  background: url(../../assets/mobile/roadmap_bg.png) top center no-repeat;
  background-size: 100% 100%;
  .container{
    padding: 0 .22rem;
    .title{
      font-size: .24rem;
      line-height: .36rem;
      font-family: 'MEBlack';
      color: #2CFF98;
      margin: 0;
      margin-bottom: .16rem;
    }
    .list{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: .07rem;
      grid-column-gap: .07rem;
      grid-auto-flow: column dense;
      .item{
        height: 1.57rem;
        
      }
    }
    .list_bottom{
      margin: .07rem 0; 
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: .07rem;
      grid-column-gap: .07rem;
      grid-auto-flow: column dense;
      .item{
        height: .8rem;
        
      }
    }
    .chapter{
      margin-top: .34rem;
      color: #fff;
      .chapter_title{
        font-size: .24rem;
        line-height: .32rem;
        font-family: 'MEBlack';
        margin: 0;
        color: #2CFF98;
      }
      .des{
        font-family: 'AvenirNext-Medium';
        font-size: .15rem;
        line-height: .2rem;
        color: rgba(255, 255, 255, 0.8);
        margin: .04rem 0 .08rem;
      }
    }
  }
}